import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Callout, CalloutTitle, CalloutText } from '../../components/callout';
import { Grid } from '../../components/grid';
import { Link } from 'gatsby';
export const pageQuery = graphql`
  {
    mdx(fields: {slug: {eq: "/principles/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageAside = makeShortcode("PageAside");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h1>{props.pageContext.frontmatter.title}</h1>
        <p>{props.pageContext.frontmatter.description}</p>
        <hr></hr>
        <h2>{`Twilio UX Principles`}</h2>
        <p>{`These principles are statements of how I work, how you work, how we work as a UX organisation. We hold ourselves accountable to the behaviours and practices for which they advocate. We challenge our work to meet the standards they call for.`}</p>
        <h3>{`How we work`}</h3>
        <h4>{`Think big and take small steps`}</h4>
        <p>{`Work methodically towards a compelling vision. Be willing to iterate your work as you build an understanding of customer needs, capturing the vision as you adjust and progress.`}</p>
        <h4>{`Start with the customer`}</h4>
        <p>{`Understand the customer, their problem, and the context first. Begin by conducting or seeking out the customer research that will enable you to create a compelling solution.`}</p>
        <h4>{`Seek feedback early and often`}</h4>
        <p>{`Share work while it’s still scrappy to shape its direction. Collaborate with other disciplines to expose different ideas that challenge your thinking and strengthen your work as it evolves.`}</p>
        <h4>{`Validate work with customer testing`}</h4>
        <p>{`Invite real customers to test and challenge solutions. Gather robust feedback and iterate your work until it both solves the customer problem and improves the overall product experience.`}</p>
        <h4>{`Shepherd work all the way to the customer`}</h4>
        <p>{`Take responsibility for every detail that’s delivered to the customer. Share the work’s intent with your team and ensure that its implementation is true to the vision.`}</p>
        <h3>{`How we build`}</h3>
        <h4>{`Develop a seamless experience`}</h4>
        <p>{`Create customer experiences that are unified in look and feel. Make the transitions between business units unnoticeable with consistent use of styles, patterns, and interactions.`}</p>
        <h4>{`Refine to the essentials`}</h4>
        <p>{`Choose every element with a clear purpose that’s attached to a customer need. Refine the experience until every component is essential in solving the customer problem.`}</p>
        <h4>{`Set the bar higher`}</h4>
        <p>{`Meet the high standard that’s expected, then push past it. Elevate function, form, language, and design to ensure that the quality of the experience raises the bar for future work.`}</p>
        <h4>{`Recognize the diversity of every user`}</h4>
        <p>{`Understand that our users are not all like us. Work with empathy and consideration, offering guidance and encouragement to create an inclusive experience.`}</p>
        <h2>{`Design System Principles`}</h2>
        <p>{`The Design Systems team won't ship anything unless:`}</p>
        <h3>{`It's Inclusive`}</h3>
        <p>{`We build a floor for every experience at Twilio. It’s crucial that we consider the voices of people who don’t look like us, speak different languages, work in different parts of the world, or use our work in different ways.`}</p>
        <h3>{`It's a Multiplier`}</h3>
        <p>{`We don’t work on something unless its a base common denominator for a number of exceptional experiences. Our work should multiply upstream and downstream efforts.`}</p>
        <h3>{`It's Referenceable`}</h3>
        <p>{`Systems should never have single points of failure. Good documentation, reliable APIs, and an intuitive language of operation are necessary to ensure that what we do works, and works well.`}</p>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      